<template>
  <v-app>
    <!--mainwrap-->
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Sinyal 1Inch ETH ERC
                <button @click="kalkulatorTool('USDT', 0)" class="btn btn-info ml-2"><b-icon-calculator-fill /> Kalkulator</button>
              </h3>
              <span class="ml-2">FEE </span>
              <b-form-input v-model="gas_wd" class="nospin ml-2" style="width: 60px" id="limit_threshold" type="number"></b-form-input>
              <span class="ml-2">USD : {{ gas_usd.toFixed(0) }}</span>
              <span class="ml-2">ETH : {{ gas_eth.toFixed(4) }}</span>
              <span class="ml-2">GWEI : {{ gas_gwei }}</span>
              <a class="ml-2" title="Update gas price" @click="updateBinanceDanGas" v-if="!gas_loading"><b-icon-cloud-download-fill /></a>
              <div class="spinner-border spinner-border-sm" v-else></div>
              <b-form-checkbox class="ms-2" switch v-model="auto_request">Autorun</b-form-checkbox>
            </div>
            <div class="card-toolbar">
              <span class="mr-2" v-if="axiosError.timeout.length > 0">
                <span style="color: brown; font-weight: bold; font-size: 14px" :title="axiosError.timeout.join('\n')"><b-icon-clock /> {{ axiosError.timeout.length }} Timeout</span> |
              </span>
              <span class="mr-2" v-if="axiosError.error.length > 0">
                <span style="color: #ff0000; font-weight: bold; font-size: 14px" :title="axiosError.error.join('\n')"><b-icon-exclamation-triangle /> {{ axiosError.error.length }} Error</span> |
              </span>
              <span class="mr-2" v-if="threshold > 0">
                <span style="color: #00aa00; font-weight: bold; font-size: 14px"><b-icon-bell /> {{ threshold }} Sinyal</span>
              </span>
              <button
                v-if="!(running_request > 0 || request_run)"
                :disabled="sinyal.fetching"
                @click="registerAndStartFetchSinyal1InchEthErc()"
                class="btn btn-primary mr-1"
                title="Klik reset untuk menerapkan perubahan modal ke semua coin"
              >
                <b-icon-back /> Setup
              </button>
              <span v-if="running_request > 0 || request_run" class="text-muted"
                ><b-spinner small /> Update Harga Koin {{ (((total_request - running_request) / total_request) * 100).toFixed(1) }}%
              </span>
              <button v-else :disabled="sinyal.fetching" @click="startFetchSinyal1InchEthErc()" class="btn btn-success" style="background: #00dd00" title="Klik refresh untuk memperbarui data">
                <b-icon-clock /> Update
              </button>
              <button v-if="running_request > 0 || request_run" @click="$router.go()" class="btn btn-danger ml-2">Stop/Refresh</button>
              <button @click="openModalLog" class="btn btn-danger ml-2"><b-icon-clock-history /> Log</button>
            </div>
          </div>
          <div class="card-body">
            <b-row>
              <b-col md="12" class="mb-5">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th class="text-center p-1" style="background-color: #f0f8ff">USDT/IDR</th>
                      <th class="text-center p-1" style="background-color: #ffffff">BTC/IDR</th>
                      <th class="text-center p-1" style="background-color: #f0f8ff">ETH/IDR</th>
                      <th class="text-center p-1" style="background-color: #ffffff">ETH/IDR</th>
                      <th class="text-center p-1" style="background-color: #f0f8ff">BTC/USDT</th>
                      <th class="text-center p-1" style="background-color: #ffffff">ETH/USDT</th>
                      <th class="text-center p-1" style="background-color: #f0f8ff">ETH/USDT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #f0f8ff">{{ harga.usdtbidr }}</td>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #ffffff">{{ harga.btcbidr }}</td>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #f0f8ff">{{ harga.bnbbidr }}</td>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #ffffff">{{ harga.ethbidr }}</td>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #f0f8ff">{{ harga.btcusdt }}</td>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #ffffff">{{ harga.bnbusdt }}</td>
                      <td class="text-center font-weight-bolder p-1" style="background-color: #f0f8ff">{{ harga.ethusdt }}</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group id="modal_kiri_1inch_eth_erc" label="Modal Binance:" label-cols-sm="6" label-align-sm="right" label-for="modal_kiri_1inch_eth_erc">
                  <b-form-input id="modal_kiri_1inch_eth_erc" type="number" placeholder="Modal Binance" :value="modal_kiri_1inch_eth_erc" @input="updateModalKiri" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="modal_kanan_1inch_eth_erc" label="Modal 1Inch:" label-cols-sm="6" label-align-sm="right" label-for="modal_kanan_1inch_eth_erc">
                  <b-form-input id="modal_kanan_1inch_eth_erc" type="number" placeholder="Modal 1Inch" :value="modal_kanan_1inch_eth_erc" @input="updateModalKanan" required></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="limit_threshold" label="Filter PNL:" label-cols-sm="6" label-align-sm="right" label-for="limit_threshold">
                  <b-input-group>
                    <b-form-input id="limit_threshold" type="number" placeholder="Filter PNL" v-model="limit_threshold" required></b-form-input>
                    <b-input-group-append is-text title="Sembunyikan yg tidak masuk kriteria">
                      <b-form-checkbox switch class="mr-n2" v-model="sinyal.table_filter" value="y" unchecked-value="" />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="3" class="text-center font-weight-bold">
                <span class="">Last Fetch:{{ lastFetchSinyal1InchEthErc }}</span>
              </b-col>
            </b-row>
            <b-table
              bordered
              sticky-header="900px"
              head-variant="dark"
              responsive=""
              hover
              :items="listSinyal1InchEthErc"
              :fields="sinyal.fields"
              :tbody-tr-class="rowClass"
              :filter-function="filterTable"
              :filter="sinyal.table_filter"
            >
              <template slot="thead-top" style="position: sticky">
                <b-tr>
                  <b-th>Modal</b-th>
                  <b-th colspan="2">PRICE</b-th>
                  <b-th colspan="2" variant="primary">SELISIH</b-th>
                  <b-th>SYMBOL</b-th>
                  <b-th>Modal</b-th>
                  <b-th colspan="2">PRICE</b-th>
                  <b-th colspan="2" variant="primary">SELISIH</b-th>
                  <b-th :title="jml_koin - jml_koin_aktif + ' koin dinonaktifkan'">{{ jml_koin - jml_koin_aktif }}</b-th>
                </b-tr>
              </template>
              <template #cell(symbol)="data">
                <a :href="listSettings.url_coin_erc + data.item.coin_address" target="_pasar">{{ data.value }}</a>
                <span :class="'ml-2 badge badge-pill badge-pair-' + data.item.pair">{{ data.item.pair }}</span>
                {{ data.item.waktu_fetch }}
                <br />
                <small style="cursor: copy" :id="'coin_address_' + data.item.symbol" @click="CopyToClipboard('coin_address_' + data.item.symbol)">{{ data.item.coin_address }}</small>
              </template>
              <template #cell(binance_ask_price)="data">
                <a target="koin" :href="'https://www.binance.com/en/trade/' + data.item.symbol + '_' + data.item.pair + '?type=spot'">
                  <span :id="'binance_ask_price_' + data.item.symbol" v-html="parseFloat(data.value).toFixed(8)"></span>
                </a>
                <br />
                <a class="price-tool mr-2" style="cursor: copy" title="Klik untuk copy" @click="CopyToClipboard('binance_ask_price_' + data.item.symbol)"><b-icon-clipboard-plus /></a>
                <a class="price-tool" title="Klik untuk mengkalkulasi" @click="kalkulatorTool(data.item.pair, parseFloat(data.value).toFixed(8), 'USDT')"><b-icon-calculator /></a>
              </template>
              <template #cell(binance_bid_price)="data">
                <a target="koin" :href="'https://www.binance.com/en/trade/' + data.item.symbol + '_' + data.item.pair + '?type=spot'">
                  <span :id="'binance_bid_price_' + data.item.symbol" v-html="parseFloat(data.value).toFixed(8)"></span>
                </a>
                <br />
                <a class="price-tool mr-2" style="cursor: copy" title="Klik untuk copy" @click="CopyToClipboard('binance_bid_price_' + data.item.symbol)"><b-icon-clipboard-plus /></a>
                <a class="price-tool" title="Klik untuk mengkalkulasi" @click="kalkulatorTool(data.item.pair, parseFloat(data.value).toFixed(8), 'USDT')"><b-icon-calculator /></a>
              </template>
              <template #cell(inch_usdt)="data">
                <a target="koin2" :href="'https://app.1inch.io/#/1/classic/swap/' + data.item.coin_address + '/ETH'">
                  <span :id="'inch_usdt' + data.item.symbol" v-html="data.value"></span>
                </a>
                <br />
                <a class="price-tool mr-2" style="cursor: copy" title="Klik untuk copy" @click="CopyToClipboard('inch_usdt' + data.item.symbol)"><b-icon-clipboard-plus /></a>
                <a class="price-tool" title="Klik untuk mengkalkulasi" @click="kalkulatorTool(data.item.pair, parseFloat(data.value).toFixed(8), 'ETH')"><b-icon-calculator /></a>
              </template>
              <template #cell(selisih_pnl_kiri)="data">
                <span v-bind:class="{ 'bg-primary text-white p-2': parseFloat(data.value) >= parseFloat(limit_threshold) }" v-html="data.value"></span>
              </template>
              <template #cell(selisih_pnl_kiri_persen)="data" class="text-center">
                <span v-html="data.value"></span>
              </template>
              <template #cell(inch_price)="data">
                <a target="koin2" :href="'https://app.1inch.io/#/1/classic/swap/ETH/' + data.item.coin_address">
                  <span :id="'inch_price' + data.item.symbol" v-html="data.value"></span>
                </a>
                <br />
                <a class="price-tool mr-2" style="cursor: copy" title="Klik untuk copy" @click="CopyToClipboard('inch_price' + data.item.symbol)"><b-icon-clipboard-plus /></a>
                <a class="price-tool" title="Klik untuk mengkalkulasi" @click="kalkulatorTool(data.item.pair, parseFloat(data.value).toFixed(8), 'ETH')"><b-icon-calculator /></a>
              </template>
              <!-- <template #cell(inch_sum)="data">
                <span v-html="data.value"></span>
              </template> -->
              <template #cell(selisih_pnl_kanan)="data">
                <span v-bind:class="{ 'bg-primary text-white p-2': parseFloat(data.value) >= parseFloat(limit_threshold) }" v-html="data.value"></span>
              </template>
              <template #cell(selisih_pnl_kanan_persen)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(modal_kiri)="data">
                <input class="form-control" type="number" v-on:change="indvModal($event, data.item.symbol, 'kiri')" :value="data.value" title="Ketikkan modal, tekan enter untuk memuat." />
              </template>
              <template #cell(modal_kanan)="data">
                <input class="form-control" type="number" v-on:change="indvModal($event, data.item.symbol, 'kanan')" :value="data.value" title="Ketikkan modal, tekan enter untuk memuat." />
              </template>
              <template #cell(num)="data">
                <span v-html="data.index + 1"></span>
                <b-form-checkbox switch :checked="data.item.aktif" v-on:change="ubahAktif(data.item.symbol, data.item.aktif)" />
              </template>
            </b-table>
          </div>
          <div class="card-footer">
            <b-row>
              <b-col md="12">
                <h4>Pengaturan update harga :</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group id="axiosDelay" label="Interval" label-cols-sm="6" label-align-sm="right" label-for="axiosDelay">
                  <b-input-group>
                    <b-form-input id="axiosDelay" type="number" placeholder="Delay" :value="axiosDelay" @input="updateDelay" required></b-form-input>
                    <b-input-group-append is-text title="Sembunyikan yg tidak masuk kriteria">ms / koin</b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group id="auto_request_delay" label="Auto Delay" label-cols-sm="6" label-align-sm="right" label-for="auto_request_delay">
                  <b-input-group>
                    <b-form-input id="auto_request_delay" type="number" placeholder="Delay" :value="auto_request_delay" @input="updateAutoRequestDelay" required></b-form-input>
                    <b-input-group-append is-text title="Milidetik">ms</b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group id="axiosBatch" label="Jeda:" label-cols-sm="3" label-align-sm="right" label-for="axiosBatch">
                  <b-input-group>
                    <b-form-input id="axiosRehat" type="number" placeholder="Rehat" :value="axiosRehat" @input="updateRehat" required></b-form-input>
                    <b-input-group-append is-text title="">ms per-</b-input-group-append>
                    <b-form-input id="axiosBatch" type="number" placeholder="Rehat" :value="axiosBatch" @input="updateBatch" required></b-form-input>
                    <b-input-group-append is-text title="">koin</b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row></b-row>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->
    <b-modal ref="modal-log" title="Log Request" size="xl">
      <div style="height: 400px; overflow: auto">
        <ul>
          <li v-for="(item, index) in log_request" :key="index">{{ item }}</li>
        </ul>
      </div>
    </b-modal>
    <b-modal ref="modal-calculator" title="Kalkulator" size="md" ok-only ok-title="Tutup">
      <kalkulator :pair="kalkulator_data.pair" :harga="kalkulator_data.harga" :trigger="kalkulator_data.trigger" :defaultSource="kalkulator_data.defaultSource" />
    </b-modal>
  </v-app>
  <!--mainwrap-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import axios from "axios";
import Swal from "sweetalert2";
import Kalkulator from "../../components/Kalkulator.vue";

export default {
  data() {
    return {
      sinyal: {
        fetching: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 200,
        filter: null,
        filterOn: ["symbol"],
        table_filter: "",
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "modal_kiri",
            label: "Binance",
            sortable: false,
            tdClass: "text-center font-weight-bold",
            thStyle: {
              width: "10%",
            },
          },
          {
            key: "binance_ask_price",
            label: "Binance(Ask)",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-price-binance",
          },
          {
            key: "inch_usdt",
            label: "1Inch",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-price-1inch",
          },
          {
            key: "selisih_pnl_kiri",
            label: "PNL",
            sortable: false,
            tdClass: "text-center font-weight-bold",
          },
          {
            key: "selisih_pnl_kiri_persen",
            label: "%",
            sortable: false,
            tdClass: "text-center font-weight-bold",
          },
          {
            key: "symbol",
            label: "Coin",
            sortable: true,
            tdClass: "text-center font-weight-bold bg-grey-100 text-dinamis",
            thStyle: {
              width: "40%",
            },
          },
          {
            key: "modal_kanan",
            label: "1Inch",
            sortable: false,
            tdClass: "text-center font-weight-bold",
            thStyle: {
              width: "10%",
            },
          },
          {
            key: "inch_price",
            label: "1Inch",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-price-1inch",
          },
          {
            key: "binance_bid_price",
            label: "Binance(Bid)",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-price-binance",
          },
          // {
          //   key: "inch_sum",
          //   label: "SUM",
          //   sortable: false,
          //   tdClass: "text-center font-weight-bold",
          // },
          {
            key: "selisih_pnl_kanan",
            label: "PNL",
            sortable: false,
            tdClass: "text-center font-weight-bold",
          },
          {
            key: "selisih_pnl_kanan_persen",
            label: "%",
            sortable: false,
            tdClass: "text-center font-weight-bold",
          },
          {
            key: "num",
            label: "#",
            sortable: false,
            tdClass: "text-center font-weight-bold bg-ireng text-white",
          },
        ],
        items: [],
      },
      modal_kiri_1inch_eth_erc: 0,
      modal_kanan_1inch_eth_erc: 0,
      running_request: 0,
      total_request: 0,
      auto_request: false,
      auto_request_delay: 5000,
      request_run: false,
      threshold: 0,
      limit_threshold: 3,
      log_request: [],
      axiosCancelToken: axios.CancelToken,
      axiosCancel: null,
      axiosDelay: 500,
      axiosRehat: 10000,
      axiosBatch: 25,
      axiosError: {
        timeout: [],
        error: [],
      },
      harga: {
        btcusdt: 0,
        btcbidr: 0,
        ethusdt: 0,
        ethbidr: 0,
        bnbusdt: 0,
        bnbbidr: 0,
        usdtbidr: 0,
      },
      kalkulator_data: {
        pair: "USDT",
        harga: 0,
        trigger: true,
        defaultSource: "USDT",
      },
      testing: "",
      jml_koin_aktif: 0,
      jml_koin: 0,
      gas_usd: 0,
      gas_eth: 0,
      gas_gwei: 0,
      gas_wd: 0,
      gas_loading: false,
      skip_iteration: 0,
    };
  },
  components: { Kalkulator },
  watch: {
    limit_threshold() {
      this.$store.dispatch("settingSet", { name: "filter_pnl_1inch_eth_erc", value: this.limit_threshold });
      this.recalculateThreshold();
    },
    gas_wd() {
      this.$store.dispatch("settingSet", { name: "gas_wd_erc", value: this.gas_wd });
    },
    running_request(newVal, oldVal) {
      if (newVal == 0 && oldVal != 0) {
        if (this.auto_request) {
          this.request_run = true;
          this.$bvToast.toast("Request selesai, me-refresh dalam " + this.auto_request_delay + "ms", {
            title: `Autorun`,
            variant: "success",
            solid: true,
          });
          setTimeout(() => {
            this.startFetchSinyal1InchEthErc();
          }, this.auto_request_delay);
        } else {
          this.request_run = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["listSinyal1InchEthErc", "lastFetchSinyal1InchEthErc", "listSettings", "listBookTicker", "currentUser"]),
  },
  mounted() {
    if (!(this.currentUser.level_akses == 1 || this.currentUser.fitur.find(i => i == "sinyal_1inch_eth_erc") != undefined)) {
      this.$router.push({ name: "setting" });
    }
    this.modal_kiri_1inch_eth_erc = this.listSettings === null ? 400 : this.listSettings.modal_kiri_1inch_eth_erc || 400;
    this.modal_kanan_1inch_eth_erc = this.listSettings === null ? 400 : this.listSettings.modal_kanan_1inch_eth_erc || 400;
    this.limit_threshold = this.listSettings === null ? 5 : this.listSettings.filter_pnl_1inch_eth_erc || 5;
    this.axiosDelay = this.listSettings === null ? 500 : this.listSettings.delay_1inch_eth_erc || 500;
    this.axiosRehat = this.listSettings === null ? 10000 : this.listSettings.rehat_1inch_eth_erc || 10000;
    this.axiosBatch = this.listSettings === null ? 25 : this.listSettings.batch_1inch_eth_erc || 25;
    this.gas_wd = this.listSettings === null ? 5 : this.listSettings.gas_wd_erc || 0;
    this.auto_request_delay = this.listSettings === null ? 5000 : this.listSettings.auto_request_delay || 5000;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Market Watch 1Inch ETH ERC" }]);
    this.recalculateThreshold();

    //harga
    this.updateHarga();
    this.jml_koin = this.$store.state.coin.coins_erc.length;
    this.jml_koin_aktif = this.$store.state.coin.coins_erc.filter(x => x.aktif_1inch == true).length;
    this.list_koin_nonaktif = this.$store.state.coin.coins_erc.filter(x => x.aktif_1inch == false);
  },
  methods: {
    ubahAktif(symbol, current) {
      this.$store.dispatch("updateSinyal1InchEthErc", {
        symbol: symbol,
        value: {
          aktif: !current,
        },
      });
      this.$store.dispatch("updateAktifCoinErc", {
        nama_coin: symbol,
        edit: false,
        changes: {
          aktif_1inch: !current,
        },
      });
      this.jml_koin_aktif = this.$store.state.coin.coins_erc.filter(x => x.aktif_1inch == true).length;
      this.list_koin_nonaktif = this.$store.state.coin.coins_erc.filter(x => x.aktif_1inch == false);
    },
    updateBinanceDanGas() {
      this.gas_loading = true;
      this.$store.dispatch("updateBookTicker").then(() => {
        this.updateHarga();
        this.updateGas();
      });
    },
    updateGas() {
      this.gas_loading = true;
      axios
        .get("https://api.etherscan.io/api", {
          params: {
            module: "gastracker",
            action: "gasoracle",
          },
        })
        .then(response => {
          if (response.data.message == "NOTOK") {
            //kecepeten
            this.$bvToast.toast("Tunggu 5 Detik sebelum update gas price", {
              title: `Limited`,
              variant: "danger",
              solid: true,
            });
          } else if (response.data.result.FastGasPrice != undefined) {
            this.gas_gwei = response.data.result.FastGasPrice;
            this.gas_eth = (this.gas_gwei / 1000000000) * 260000;
            let asem = this.gas_eth * this.harga.ethusdt;
            this.gas_usd = asem + parseInt(this.gas_wd);
          } else {
            //error
            this.$bvToast.toast("Cek jaringan", {
              title: `Error update gas`,
              variant: "danger",
              solid: true,
            });
          }
          this.gas_loading = false;
        })
        .catch(error => {
          console.log(error);
          this.gas_loading = false;
          this.$bvToast.toast("Cek jaringan", {
            title: `Error update gas`,
            variant: "danger",
            solid: true,
          });
        });
    },
    addLogRequest(text) {
      this.log_request.push("[" + new Date().toISOString() + "]" + text);
    },
    openModalLog() {
      this.$refs["modal-log"].show();
    },
    recalculateThreshold() {
      this.threshold = 0;
      this.listSinyal1InchEthErc.forEach(item => {
        if (parseFloat(item.selisih_pnl_kanan) >= this.limit_threshold || parseFloat(item.selisih_pnl_kiri) >= this.limit_threshold) this.threshold++;
      });
    },
    getBidPrice(pair) {
      let data = this.listBookTicker.find(item => item.symbol == pair);
      if (data == undefined) {
        return 0;
      } else {
        return data.bidPrice;
      }
    },
    clearHarga() {
      this.harga.btcusdt = "loading";
      this.harga.btcbidr = "loading";
      this.harga.ethusdt = "loading";
      this.harga.ethbidr = "loading";
      this.harga.bnbusdt = "loading";
      this.harga.bnbbidr = "loading";
      this.harga.usdtbidr = "loading";
    },
    updateHarga() {
      this.harga.btcusdt = this.getBidPrice("BTCUSDT");
      this.harga.btcbidr = this.getBidPrice("BTCBIDR");
      this.harga.ethusdt = this.getBidPrice("ETHUSDT");
      this.harga.ethbidr = this.getBidPrice("ETHBIDR");
      this.harga.bnbusdt = this.getBidPrice("ETHUSDT");
      this.harga.bnbbidr = this.getBidPrice("ETHBIDR");
      this.harga.usdtbidr = this.getBidPrice("USDTBIDR");
    },
    filterTable(row, filter) {
      // console.log("filter", filter);
      if (filter != "") {
        if (row.aktif && (parseFloat(row.selisih_pnl_kanan) >= this.limit_threshold || parseFloat(row.selisih_pnl_kiri) >= this.limit_threshold)) {
          return true;
        } else {
          return false;
        }
      }
    },
    indvModal(val, sym, k) {
      // console.log(val.target.value, sym, k);
      this.fetchSinyal1InchEthErc(sym, { ["modal_" + k]: val.target.value });
    },
    updateModalKiri(e) {
      this.$store.dispatch("settingSet", { name: "modal_kiri_1inch_eth_erc", value: e });
    },
    updateModalKanan(e) {
      this.$store.dispatch("settingSet", { name: "modal_kanan_1inch_eth_erc", value: e });
    },
    updateDelay(e) {
      this.axiosDelay = e;
      this.$store.dispatch("settingSet", { name: "delay_1inch_eth_erc", value: e });
    },
    updateAutoRequestDelay(e) {
      this.auto_request_delay = e;
      this.$store.dispatch("settingSet", { name: "auto_request_delay", value: e });
    },
    updateRehat(e) {
      this.axiosRehat = e;
      this.$store.dispatch("settingSet", { name: "rehat_1inch_eth_erc", value: e });
    },
    updateBatch(e) {
      this.axiosBatch = e;
      this.$store.dispatch("settingSet", { name: "batch_1inch_eth_erc", value: e });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (parseFloat(item.selisih_pnl_kanan) >= this.limit_threshold || parseFloat(item.selisih_pnl_kiri) >= this.limit_threshold) return "bg-green";
    },
    refreshSinyal1InchEthErc() {
      this.$store.dispatch("registerSinyal1InchEthErc");
    },
    test2() {
      // let symbol = "ALICE";
      // let coin = this.$store.state.coin.coins_erc.find(item => item.nama_coin == symbol);
      let modal = 400;
      // let askPrice = 5.2024;
      // let api = BigInt("396470875063322314108");
      let api = BigInt("358516543755854128279");
      let pembagi = BigInt("1".padEnd(18 + 1, "0"));
      // let hasil = modal / askPrice;
      let hasil = (Number((api * 100n) / pembagi) / 100 - modal).toFixed(2);
      console.log(pembagi);
      console.log(hasil);
    },
    test3() {
      // amount=(10^desimal)*(modal/binance_ask_price)
      // 76887590342918700000
      let symbol = "1Inch";
      let coin = this.$store.state.coin.coins_erc.find(item => item.nama_coin == symbol);
      // let pengali = BigInt("1".padEnd(parseInt(coin.desimal) + 1, "0"));
      let modal = 400;
      let askPrice = 5.2024;
      let hasil = (modal / askPrice) * Math.pow(10, parseInt(coin.desimal));
      console.log(hasil.toString());
    },
    test4() {
      let hasil = this.modal_kanan_1inch_eth_erc * Math.pow(10, 18);
      console.log(hasil);
    },
    registerAndStartFetchSinyal1InchEthErc2() {
      for (let iteration = 0; iteration < 110; iteration++) {
        let rehat_1inch_eth_erc = 10000 * Math.floor(iteration / 25);
        let penambah = 25 * Math.floor(iteration / 25) * this.axiosDelay + rehat_1inch_eth_erc;
        console.log("to " + iteration, iteration * this.axiosDelay + penambah, rehat_1inch_eth_erc, penambah);
      }
    },
    registerAndStartFetchSinyal1InchEthErc() {
      this.clearHarga();
      this.axiosCancel = this.axiosCancelToken.source();
      this.addLogRequest("START SETUP");
      if (this.$store.state.coin.coins_erc == null) {
        this.addLogRequest("KOIN KOSONG");
        Swal.fire({
          title: "Belum ada koin yg didaftarkan!",
          text: "Silahkan impor settingan dahulu",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.threshold = 0;
      this.total_request = 0;
      this.$store.dispatch("clearSinyal1InchEthErc").then(() => {
        this.total_request++;
        this.$store.dispatch("updateBookTicker").then(response => {
          console.log("respon", response);
          this.updateHarga();
          if (response.status != "ok") {
            Swal.fire({
              title: "Gagal memuat book ticker!",
              text: response.message,
              icon: "error",
              heightAuto: false,
            });
          } else {
            this.addLogRequest("BINANCE UPDATED");
            this.$store.dispatch("registerSinyal1InchEthErc").then(() => {
              this.skip_iteration = 0;
              this.listSinyal1InchEthErc.forEach((item, iteration) => {
                if (!item.aktif) {
                  this.skip_iteration++;
                } else {
                  this.fetchSinyal1InchEthErc(item.symbol, undefined, iteration - this.skip_iteration);
                }
              }); // forEach
            }); // dispatch registerSinyal1InchEthErc
            this.$bvToast.toast("Book Ticker berhasil binance di update", {
              title: `Binance Book Ticker`,
              variant: "success",
              solid: true,
            });
          }
        });
      }); // dispatch clearSinyal1InchEthErc
    },
    cancelFetch() {
      window.location.reload(false);
      // this.axiosCancel.cancel();
    },
    startFetchSinyal1InchEthErc() {
      this.clearHarga();
      this.axiosCancel = this.axiosCancelToken.source();
      this.threshold = 0;
      this.total_request = 0;
      this.$store.dispatch("clearSinyal1InchEthErc").then(() => {
        this.total_request++;
        this.$store.dispatch("updateBookTicker").then(response => {
          // console.log("respon", response);
          this.updateHarga();
          if (response.status != "ok") {
            Swal.fire({
              title: "Gagal memuat book ticker!",
              text: response.message,
              icon: "error",
              heightAuto: false,
            });
          } else {
            this.addLogRequest("BINANCE UPDATED");
            this.skip_iteration = 0;
            this.listSinyal1InchEthErc.forEach((item, iteration) => {
              if (!item.aktif) {
                this.skip_iteration++;
              } else {
                this.fetchSinyal1InchEthErc(item.symbol, undefined, iteration - this.skip_iteration);
              }
            }); // forEach
            this.$bvToast.toast("Book Ticker berhasil binance di update", {
              title: `Binance Book Ticker`,
              variant: "success",
              solid: true,
            });
          }
        });
      }); // dispatch clearSinyal1InchEthErc
    },
    fetchSinyal1InchEthErc(symbol, addition, iteration) {
      // let istirahat = iteration % 25 == 0 ? iteration * 10000 : 0;
      let rehat_1inch_eth_erc = this.axiosRehat * Math.floor(iteration / this.axiosBatch);
      let penambah = this.axiosDelay * iteration;
      this.axiosError.timeout = [];
      this.axiosError.error = [];
      this.running_request += 2;
      this.total_request += 2;
      let sinyal = this.listSinyal1InchEthErc.find(item => item.symbol == symbol);
      let coin = this.$store.state.coin.coins_erc.find(item => item.nama_coin == symbol);
      if (addition == undefined) {
        addition = {};
      }
      let askPrice = this.$store.getters.getBookCoinPriceAsk(symbol);
      let bidPrice = this.$store.getters.getBookCoinPriceBid(symbol);
      // if (iteration !== null) {
      //   setTimeout(() => {
      //     this.running_request -= 2;
      //   }, iteration * 10);
      //   return true;
      // }
      setTimeout(() => {
        this.$store
          .dispatch("updateSinyal1InchEthErc", {
            symbol: symbol,
            value: {
              ...addition,
              binance_ask_price: askPrice.price,
              binance_bid_price: bidPrice.price,
              selisih_pnl_kiri: "<div class='spinner-border spinner-border-sm'></div>",
              inch_usdt: "<div class='spinner-border spinner-border-sm'></div>",
              selisih_pnl_kiri_persen: "<div class='spinner-border spinner-border-sm'></div>",
              inch_sum: "<div class='spinner-border spinner-border-sm'></div>",
              inch_price: "<div class='spinner-border spinner-border-sm'></div>",
              selisih_pnl_kanan: "<div class='spinner-border spinner-border-sm'></div>",
              selisih_pnl_kanan_persen: "<div class='spinner-border spinner-border-sm'></div>",
            },
          })
          .then(() => {
            if (sinyal.binance_ask_price == 0) {
              this.$store.dispatch("updateSinyal1InchEthErc", {
                symbol: symbol,
                value: {
                  ...addition,
                  selisih_pnl_kiri: 0,
                  inch_usdt: 0,
                  selisih_pnl_kiri_persen: 0,
                  inch_sum: 0,
                  inch_price: 0,
                  selisih_pnl_kanan: 0,
                  selisih_pnl_kanan_persen: 0,
                },
              });
              this.running_request -= 2;
              return true;
            }
            let modal_kiri = addition.modal_kiri != undefined ? addition.modal_kiri : sinyal.modal_kiri;
            let modal_kanan = addition.modal_kanan != undefined ? addition.modal_kanan : sinyal.modal_kanan;
            // console.log(modal_kiri / askPrice);
            // console.log(((modal_kiri / askPrice) * Math.pow(10, parseInt(coin.desimal))).toLocaleString("fullwide", { useGrouping: false }));
            // console.log(parseInt((modal_kiri / askPrice) * Math.pow(10, parseInt(coin.desimal))));
            // let amount = BigInt(parseInt((modal_kiri / askPrice) * Math.pow(10, parseInt(coin.desimal)))).toString();
            // console.log(amount);
            let amount = (((modal_kiri * this.harga.bnbusdt) / askPrice.price) * Math.pow(10, parseInt(coin.desimal))).toLocaleString("fullwide", { useGrouping: false, maximumFractionDigits: 0 });
            axios
              .get("https://api.1inch.exchange/v3.0/1/quote", {
                cancelToken: this.axiosCancel.token,
                params: {
                  fromTokenAddress: coin.coin_address,
                  toTokenAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                  amount,
                },
                timeout: (this.$store.state.setting.settings.timeout_request || 15) * 1000,
              })
              .then(response => {
                this.running_request -= 1;
                let data = response.data;

                // kalkulasi selisih pnl kiri
                let api = BigInt(data.toTokenAmount);
                let pembagi = BigInt("1".padEnd(19, "0"));
                let selisih_pnl_kiri = Number((api * 10000n) / pembagi) / 10000 - modal_kiri;

                // inch_usdt
                // ( modal_kiri + API_INCH_FROM ) / ( modal_kiri / binance_ask_price )
                // let inch_usdt = (parseInt(modal_kiri) + selisih_pnl_kiri) / (parseInt(modal_kiri) / askPrice.price);
                let inch_usdt = Number((api * 10000n) / pembagi) / 10000 / ((modal_kiri * this.harga.bnbusdt) / askPrice.price);

                // kalkulasi selisih_pnl_kiri_persen
                // ((inch_usdt-binance_ask_price)/binance_ask_price)*100
                let selisih_pnl_kiri_persen = (selisih_pnl_kiri / modal_kiri) * 100;

                this.$store.dispatch("updateSinyal1InchEthErc", {
                  symbol: symbol,
                  value: {
                    selisih_pnl_kiri: selisih_pnl_kiri.toFixed(3),
                    inch_usdt: inch_usdt.toFixed(7),
                    selisih_pnl_kiri_persen: selisih_pnl_kiri_persen.toFixed(2),
                  },
                });

                let amount2 = BigInt(modal_kanan * Math.pow(10, 18)).toLocaleString("fullwide", { useGrouping: false, maximumFractionDigits: 0 });
                axios
                  .get("https://api.1inch.exchange/v3.0/1/quote", {
                    cancelToken: this.axiosCancel.token,
                    params: {
                      fromTokenAddress: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
                      toTokenAddress: coin.coin_address,
                      amount: amount2,
                    },
                    timeout: (this.$store.state.setting.settings.timeout_request || 15) * 1000,
                  })
                  .then(response => {
                    this.running_request -= 1;
                    let data2 = response.data;

                    // kalkulasi inch_sum
                    let api2 = BigInt(data2.toTokenAmount);
                    let pembagi = BigInt("1".padEnd(parseInt(coin.desimal) + 1, "0"));
                    let inch_sum = Number((api2 * 10000n) / pembagi) / 10000;

                    // kalkulasi inch_price
                    // modal_kanan/API_INCH_TO
                    let inch_price = inch_sum == 0 ? 0 : modal_kanan / inch_sum;

                    // kalkulasi selisih_pnl_kanan
                    // ((API_INCH_TO*binance_bid_price)-modal_kanan)
                    // let selisih_pnl_kanan = inch_sum * bidPrice.price - modal_kanan;
                    let selisih_pnl_kanan = (inch_sum * bidPrice.price) / this.harga.bnbusdt - modal_kanan;

                    // kalkulasi selisih_pnl_kanan_persen
                    // ((binance_bid_price-inch_price)/inch_price)*100
                    let selisih_pnl_kanan_persen = inch_sum == 0 ? 0 : (selisih_pnl_kanan / modal_kanan) * 100;
                    if (selisih_pnl_kanan >= this.limit_threshold || selisih_pnl_kiri >= this.limit_threshold) this.threshold++;
                    let waktu = new Date();
                    this.$store.dispatch("updateSinyal1InchEthErc", {
                      symbol: symbol,
                      value: {
                        inch_sum: inch_sum.toFixed(2),
                        inch_price: inch_price.toFixed(7),
                        selisih_pnl_kanan: selisih_pnl_kanan.toFixed(3),
                        selisih_pnl_kanan_persen: selisih_pnl_kanan_persen.toFixed(2),
                        waktu_fetch: ("0" + waktu.getHours()).slice(-2) + ":" + ("0" + waktu.getMinutes()).slice(-2) + ":" + ("0" + waktu.getSeconds()).slice(-2),
                      },
                    });
                  })
                  .catch(error => {
                    this.running_request -= 1;
                    let placeholder;
                    if (error.code == "ECONNABORTED") {
                      //TIMEOUT
                      placeholder = "timeout";
                      this.addLogRequest("TIMEOUT: " + symbol + " " + coin.coin_address);
                      this.axiosError.timeout.push(symbol);
                    } else if (error.response != undefined) {
                      placeholder = "error";
                      let message = error.response.data.message || "general error";
                      this.addLogRequest("ERROR: " + symbol + " : " + message + "<br>" + error.request.responseURL);
                      this.axiosError.error.push(symbol);
                    } else if (axios.isCancel(error)) {
                      this.addLogRequest("CANCELLED: " + symbol + " " + coin.coin_address);
                      placeholder = "canceled";
                      this.axiosError.error.push(symbol);
                    } else {
                      this.addLogRequest("NETWORK ERROR: " + symbol + " " + coin.coin_address + " Request ditolak oleh 1Inch (CORS)");
                      placeholder = "error";
                      this.axiosError.error.push(symbol);
                    }
                    // this.$bvToast.toast("Error saat menghubungi 1Inch untuk koin " + symbol, {
                    //   title: `API Error`,
                    //   variant: "danger",
                    //   solid: false,
                    // });
                    let waktu = new Date();
                    this.$store.dispatch("updateSinyal1InchEthErc", {
                      symbol: symbol,
                      value: {
                        inch_sum: placeholder,
                        inch_price: placeholder,
                        selisih_pnl_kanan: placeholder,
                        selisih_pnl_kanan_persen: placeholder,
                        waktu_fetch: ("0" + waktu.getHours()).slice(-2) + ":" + ("0" + waktu.getMinutes()).slice(-2) + ":" + ("0" + waktu.getSeconds()).slice(-2),
                      },
                    });
                    console.log("Fetching 1Inch error", error);
                  });
              })
              .catch(error => {
                // console.log(error.code);
                this.running_request -= 2;
                let placeholder;
                if (error.code == "ECONNABORTED") {
                  //TIMEOUT
                  placeholder = "timeout";
                  this.addLogRequest("TIMEOUT: " + symbol + " " + coin.coin_address);
                  this.axiosError.timeout.push(symbol);
                } else if (error.response != undefined) {
                  placeholder = "error";
                  let message = error.response.data.message || "general error";
                  this.addLogRequest("ERROR: " + symbol + " : " + message + " \n " + error.request.responseURL + " \n Cek nama koin!");
                  this.axiosError.error.push(symbol);
                } else if (axios.isCancel(error)) {
                  this.addLogRequest("CANCELLED: " + symbol + " " + coin.coin_address);
                  placeholder = "canceled";
                  this.axiosError.error.push(symbol);
                } else {
                  this.addLogRequest("NETWORK ERROR: " + symbol + " " + coin.coin_address + " Request ditolak oleh 1Inch (CORS)");
                  placeholder = "error";
                  this.axiosError.error.push(symbol);
                }
                // this.$bvToast.toast("Error saat menghubungi 1Inch untuk koin " + symbol + ":\n" + error.response.data.message, {
                //   title: `Error 1inch`,
                //   variant: "danger",
                //   solid: false,
                // });
                let waktu = new Date();
                this.$store.dispatch("updateSinyal1InchEthErc", {
                  symbol: symbol,
                  value: {
                    selisih_pnl_kiri: placeholder,
                    inch_usdt: placeholder,
                    selisih_pnl_kiri_persen: placeholder,
                    inch_sum: placeholder,
                    inch_price: placeholder,
                    selisih_pnl_kanan: placeholder,
                    selisih_pnl_kanan_persen: placeholder,
                    waktu_fetch: ("0" + waktu.getHours()).slice(-2) + ":" + ("0" + waktu.getMinutes()).slice(-2) + ":" + ("0" + waktu.getSeconds()).slice(-2),
                  },
                });
                console.log("Fetching 1Inch error", error);
              });
          }); // dispatch updateSinyal1InchEthErc
      }, rehat_1inch_eth_erc + penambah);
    },
    CopyToClipboard(containerid) {
      console.log(containerid);
      let selrange = document.createRange();
      selrange.selectNode(document.getElementById(containerid));
      window.getSelection().removeAllRanges(); // clear current selection
      window.getSelection().addRange(selrange); // to select text
      document.execCommand("copy");
      window.getSelection().removeAllRanges(); // to deselect
      this.$bvToast.toast("data dicopy", {
        title: `Clipboard`,
        variant: "info",
        solid: false,
      });
    },
    kalkulatorTool(pair, harga, defaultSource) {
      this.kalkulator_data.defaultSource = defaultSource;
      this.kalkulator_data.pair = pair;
      this.kalkulator_data.harga = harga;
      this.kalkulator_data.trigger = !this.kalkulator_data.trigger;
      this.$refs["modal-calculator"].show();
    },
  },
};
</script>
